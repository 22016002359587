import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SystemMenuService } from "../../services/system-menu.service";
import { catchError, map } from "rxjs/operators";
import store from "store";
import { Router } from "@angular/router";
import { User } from "app/auth/models";
import { AuthService } from "app/base/auth/services/auth.service";
import { HotkeysService } from "angular2-hotkeys";
import { TranslateService } from "@ngx-translate/core";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { GlobalConstants } from "app/app.globalConstants";
import { CoreConfigService } from "@core/services/config.service";
import { throwError } from "rxjs";
import { environment } from "environments/environment";
import { InfraonMixpanelService } from "app/services/mixpanel/infraon-mixpanel.service";
@Component({
	selector: "app-admin-popover",
	templateUrl: "./admin-popover.component.html",
	styleUrls: ["./admin-popover.component.scss"],
})
export class AdminPopoverComponent implements OnInit {
	public donotdisturb;
	public away;
	public ssologin;
	public active = true;
	redirectToSearch = "?";
	currentAction = "active";
	awayReason = "";
	ssoLogoutUri: any;
	hideProfile = false;
	currentUser: User;
	isShown: boolean = false; // hidden by div1
	public isSelfServicePortal = false;
	public isAdminPortal = false;
	public isSupportPortal = false;
	public isNCCMenabled: boolean = false;
	permission = { moduleList: ["myprofile"], keyList: ["edit", "view"] };
	toggleShow(val, click) {
		this.isShown = val;
		if (click !== "close") {
			this.currentAction = click;
		}
	}

	myFunction() {
		// hide and show function
		var x = document.getElementById("myDIV");
		if (x.style.display === "none") {
			x.style.display = "block";
		} else {
			x.style.display = "none";
		}
	}

	saveReason = () => {
		let payload = { reason: this.awayReason };
		this._configService
			.saveAwayReason(payload)
			.pipe(
				map((response: any) => {
					if (response) {
						//console.log("response", response);
					} else {
						//console.log("failed to get favourites", response);
					}
					// Success
				}),
				catchError((error) => {
					//console.log("error", error);
					return throwError(error.statusText);
				})
			)
			.subscribe();
	};

	logout = (adminpopup) => {
		let accessType = store.get("accessType");
		if (environment?.is_oic_enable) {
			this._configService
				.getSSOLogoutUrl()
				.pipe(
					map((response: any) => {
						if (response) {
							this.ssoLogoutUri = response.data;
						} else {
							//console.log("failed to get favourites", response);
						}
					}),
					catchError((error) => {
						//console.log("error", error);
						return throwError(error.statusText);
					})
				)
				.subscribe();
		}
		this._authService
			.logout()
			.pipe(
				map((response: any) => {
					this.ssologin = localStorage.getItem("ssologin");
					this._modalService.dismissAll();
					store.remove("accessToken");
					store.remove("accessType");
					store.remove("is_expired");
					store.remove("remaining_days");
					store.remove("is_admin");
					store.remove("show_banner");
					store.remove("product");
					store.remove("ogdata");
					localStorage.removeItem("language");
					localStorage.removeItem("image_per");
					localStorage.removeItem("ssologin");
					localStorage.removeItem("imglogo");
					localStorage.removeItem("imgicon");
					localStorage.removeItem("themeColor");
					localStorage.removeItem("layoutStyle");
					localStorage.removeItem("navbar");
					localStorage.removeItem("recentFilters");
					localStorage.removeItem("lastMenuSelected");
					localStorage.removeItem("lastMenuIndex");
					localStorage.removeItem("is_call_app_enabled");
					if (this.ssologin == "true") {
						const tab = window.open(this.ssoLogoutUri, "_blank");
						tab.focus();
						this._router.navigate(["/ssologout"]);
					} else {
						if (environment.management) {
							this._router.navigate(["/"]).then(() => {});
						} else {
							if (accessType == 2) {
								this._router.navigate(["/auth/selfservice-login"]).then(() => {
									window.location.reload();
								});
							} else {
								this._router.navigate(["/auth/login"]).then(() => {
									window.location.reload();
								});
							}
						}
					}
					// this._router.navigate(["/"]);

					GlobalConstants.dnsPermissions = "";
					// Success
				}),
				catchError((error) => {
					//console.log("error", error);
					return throwError(error.statusText);
				})
			)
			.subscribe();
		localStorage.setItem("currentActiveDashbaord", null);
		// this._authService.logout();
		// this._modalService.dismissAll();
		// store.remove("accessToken");
		// localStorage.removeItem("language")
		// localStorage.removeItem("userPermissions")
		// this._router.navigate(["/"]);
		this._mixpanelService.logout();
	};

	/*
	 * Redirecting to account settings page
	 *
	 */

	editAccountSettings = () => {
		this._modalService.dismissAll();
		this._router.navigate(["/account-settings"]);
	};

	privacyPolicyNavigation = () => {
		this._modalService.dismissAll();
		this._router.navigate([]).then(() => {
			if (this.isAdminPortal || this.isSupportPortal) {
				localStorage.setItem("isFromAdminPanel", "true");
			}
			window.open("/pages/privacy-policy", "_blank");
		});
		// this._router.navigate(["/pages/privacy-policy"]);
	};
	termsServicesNavigation = () => {
		this._modalService.dismissAll();
		this._router.navigate([]).then(() => {
			if (this.isAdminPortal || this.isSupportPortal) {
				localStorage.setItem("isFromAdminPanel", "true");
			}
			window.open("/pages/terms", "_blank");
		});
		// this._router.navigate(["/pages/terms"]);
	};
	/**
	 * Constructor
	 *
	 * @param {NgbModal} modalService
	 */
	constructor(
		private _coreSidebarService: CoreSidebarService,
		public activeModal: NgbActiveModal,
		private _configService: SystemMenuService,
		private _router: Router,
		private _modalService: NgbModal,
		private _authService: AuthService,
		private hotkeysService: HotkeysService,
		public _translateService: TranslateService,
		private _coreConfigService: CoreConfigService,
		private _mixpanelService: InfraonMixpanelService
	) {
		this._coreConfigService._selfServiceSubject.subscribe((selfService) => {
			// let accessType = store.get("accessType");
			// if (selfService === "self-service" && accessType == 2) {
			if (selfService === "self-service") {
				this.isSelfServicePortal = true;
			} else {
				this.isSelfServicePortal = false;
			}
		});
		this._coreConfigService._adminSubject.subscribe((adminService) => {
			if (adminService === "admin-portal") {
				this.isAdminPortal = true;
			} else {
				this.isAdminPortal = false;
			}
		});
		this._coreConfigService._supportSubject.subscribe((supportService) => {
			if (supportService === "support-portal") {
				this.isSupportPortal = true;
			} else {
				this.isSupportPortal = false;
			}
		});

		this._authService.currentUser.subscribe((x) => (this.currentUser = x));
	}

	errorCheck() {
		//console.log("errorCheck");
	}

	ngOnInit(): void {
		this.isNCCMenabled = environment?.is_nccm_enabled;
	}

	toggleSidebar = (name): void => {
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
		this.hideProfile = true;
	};

	activeSelected() {
		this.active = true;
		this.away = false;
		this.donotdisturb = false;
	}

	awaySelected() {
		this.away = true;
		this.donotdisturb = false;
		this.active = false;
	}

	DNDSelected() {
		this.donotdisturb = true;
		this.away = false;
		this.active = false;
	}

	redirectToSelfService() {
		this._modalService.dismissAll();
		this._router.navigate(["/self-service"]);
	}

	goToPage = (page) => {
		this._modalService.dismissAll();
		let accessToken = store.get("accessToken");
		let abs_domain = "";
		let payload = {
			nccm_type: "SSO",
		};
		if (page === "nccm") {
			accessToken = accessToken.replace("infraonDNS ", "");
			this._configService.getNccmUrl(payload).subscribe((response) => {
				if (response) {
					let nccm_url = response;
					abs_domain = nccm_url + "?infraon_session=" + accessToken;
					window.open(abs_domain, "_blank");
				} else {
					window.open(abs_domain, "_blank");
				}
			});
		}
	};
}
